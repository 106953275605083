import React, { useContext, useState, useEffect, useRef } from "react";
import { withPrefix } from "gatsby";

// React Bootstrap
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
// Sanity
import { PortableText, ImageInterface, SanityProductLabelsInterface, Slug } from "../../types/SanityTypes";

// Components
import sanityImageUrlBuilder from "../../utils/imageUrlBuilder";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import SocialShare from "../SocialShare";
import ContextAwareAccordionToggle from "../ContextAwareAccordionToggle";
import Ingredients from "../Ingredients";
import VideoBlockExperimental from "../VideoBlockExperimental";
import Accessibility from "../Accessibility";
import ProductSliderV2 from "../ProductSliderV2";
import { PureVideoBlock as VideoBlock } from "../VideoBlockV2";

// Rating & Reviews
import ProductRatingsAndReviewsScript from "../ProductRatingsAndReviewsScript";
import ProductRatings from "../ProductRatings";
import ProductReviews from "../ProductReviews";

// BIN
import WhereToBuyWidget, { WhereToBuyButton } from "../WhereToBuy";

// Icons
import { GridIcon } from "../../images/icons/grid-icon";
import { ListIcon } from "../../images/icons/list-icon";

// Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Lazy, Pagination, A11y, Navigation, Thumbs } from "swiper";

// Component Styles
import "./styles.scss";

// @ts-ignore
import BlockContent from "@sanity/block-content-to-react";
import { LocalizedContext } from "../../services/LocalizedContextService";
import { getLocalizedPath } from "../../utils/gatsbyBuild";
import { event38 } from "../../analytics/event38";
import { ImageUrlBuilder } from "@sanity/image-url/lib/types/builder";

export type ProductComponentInterface = {
  sanityProductV2: ProductInterface;
  sanityProductLandingPage?: Slug;
} & SanityProductLabelsInterface &
  [];

export type ProductInterface = {
  _id: string;
  subBrand: {
    name: string;
    format: string;
  };
  variant?: {
    eVariantName?: string;
  };
  paBrandName: string;
  _rawDescription?: PortableText;
  _rawHowToUse?: PortableText;
  _rawImages: ImageInterface[];
  _rawIngredients?: PortableText;
  _rawPerfectFor?: PortableText;
  _rawTryThis?: PortableText;
  paRetailerCategory?: string;
  displayReviews: boolean;
  name: string;
  seoShortTitle: string;
  seoShortDescription: string;
  shortDescription: string;
  paSize: string;
  tags: [
    {
      tagName: string;
      _rawImage: any;
    }
  ];
  slug: {
    current: string;
  };
  paSmartLabelId?: string;
  paSmartProductId?: string;
  smartProductId?: string;
  paUPC?: string;
  promoVideo?: {
    video: {
      asset: {
        url: string;
      };
    };
  };
};

export const Product = (props: any) => {
  const { language, sanitySiteSettings, sanitySearch } = useContext(LocalizedContext);
  const [listView, setListView] = useState(false);
  const [gridView, setGridView] = useState(true);
  const [binPanel, setBinPanel] = useState(false);
  const [fixedPosition, setFixedPosition] = useState(false);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [showVariantText, setShowVariantText] = useState(() => props.sanityProductV2.variant?.eVariantName);
  const { locale, sanityId, sanityDataset, siteUrl, binConstCo, binCartWire } = useSiteMetadata(language);
  const productLabels = props.productLabels;
  const productSubBrandReferences = props.references;
  const productGoesWellWith = props.productGoesWellWith;
  const product = props.sanityProductV2;
  const assets = [...product.videos, ...(product._rawImages ?? [])];
  const currentSlug = product.slug.current;
  const productLandingPage = props.sanityProductLandingPage as Slug;

  const defaultFragrance = props.sanityProductV2.variant?.eVariantName;

  let productParentPath = "";
  if (productLandingPage) {
    productParentPath = getLocalizedPath(productLandingPage.slug.current, product._id);
  }

  const urlBuilder = sanityImageUrlBuilder({
    projectId: sanityId,
    dataset: sanityDataset
  });

  const handleGridView = () => {
    setGridView(true);
    setListView(false);
  };

  const handleListView = () => {
    setGridView(false);
    setListView(true);
  };

  // DOM Refs
  const binElement = useRef(null) as any;
  const paginationRef = useRef(null) as any;
  const gridCloumn = useRef(null) as any;

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = document?.scrollingElement?.scrollTop;
      const viewportWidth = window?.innerWidth;
      const binElementOffset =
        viewportWidth > 768 ? (binElement.current?.offsetTop as any) + 170 : binElement.current?.offsetTop + 340;
      if ((scrolled as number) > binElementOffset) {
        setBinPanel(true);
      } else {
        setBinPanel(false);
      }
      if ((scrolled as number) > gridCloumn.current?.offsetTop) {
        setFixedPosition(true);
      } else {
        setFixedPosition(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    handleScroll();
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Sort Utility
  // TODO: To be pushed in generic code class
  function compare(a: any, b: any) {
    if (a.variant.variantOrderSeq < b.variant.variantOrderSeq) {
      return -1;
    }
    if (a.variant.variantOrderSeq > b.variant.variantOrderSeq) {
      return 1;
    }
    return 0;
  }

  // Sort variants based on order sequence
  productSubBrandReferences?.length && productSubBrandReferences.sort(compare);

  // SWIPER
  SwiperCore.use([Lazy, Pagination, A11y, Navigation, Thumbs]);

  const carouselSlides = (
    asset: { alt: string | undefined; _type: string | undefined; coverImage: any; video: any },
    index: React.Key | null | undefined,
    urlBuilder: ImageUrlBuilder,
    thumbs: boolean
  ) => {
    return (
      <SwiperSlide key={index}>
        <div className="slide">
          {asset._type == "figure" || thumbs ? (
            <img
              src={
                urlBuilder
                  .image(asset._type == "figure" ? asset : asset.coverImage)
                  .auto("format")
                  .quality(100)
                  .width(thumbs ? 80 : 800)
                  .height(thumbs ? 80 : 800)
                  .format("webp")
                  .url() || undefined
              }
              loading={"lazy"}
              alt={asset.alt}
              data-testid="product-image"
              className="placeholder"
            />
          ) : (
            asset.video.asset.url && <VideoBlock data={asset} />
          )}
        </div>
      </SwiperSlide>
    );
  };

  // Thumbnail Swiper
  const thumbSwiper = () => {
    return (
      <>
        <Swiper
          lazy={true}
          speed={700}
          threshold={5}
          preloadImages={true}
          navigation={false} // set 'true' for navigation arrows
          spaceBetween={5}
          slidesPerView={8}
          freeMode={true}
          // watchSlidesProgress={true}
          onSwiper={setThumbsSwiper as any}
        >
          {assets &&
            assets.length > 0 &&
            assets.map((slide: any, index: number) => {
              return carouselSlides(slide, index, urlBuilder, true);
            })}
        </Swiper>
      </>
    );
  };

  return (
    <section className="rx-product">
      <ProductRatingsAndReviewsScript />
      <WhereToBuyWidget />

      <Container fluid>
        <Row className="specs">
          <Col md="6">
            <div className={`rx-pdp-grid__content sticky-ponel ${fixedPosition ? "is-fixed" : ""}`} ref={gridCloumn}>
              <div className={`rx-product-carousel ${product._rawImages?.length === 1 ? "is-only-item" : ""}`}>
                <Swiper
                  // lazy={true}
                  spaceBetween={0}
                  speed={700}
                  threshold={5}
                  preloadImages={true}
                  navigation={product._rawImages?.length === 1 ? false : true}
                  pagination={{ el: paginationRef.current, clickable: true }}
                  thumbs={{ swiper: thumbsSwiper }}
                  slidesPerView={1}
                >
                  {assets &&
                    assets.length > 0 &&
                    assets.map((slide: any, index: number) => {
                      return carouselSlides(slide, index, urlBuilder, false);
                    })}
                </Swiper>
                <div ref={paginationRef} className="swiper-pagination"></div>
              </div>
              <div className="rx-product-carousel-thumbs">{product._rawImages?.length > 1 ? thumbSwiper() : null}</div>
            </div>
          </Col>
          <Col md="6" className="title-and-description">
            <div className="content rx-pdp-grid__content">
              <div className="category-and-size">{product.paBrandName}</div>
              <h1>{product.name}</h1>
              {(product.smartProductId || product.paSmartProductId || product.paUPC) && (
                <div className="ratings">
                  <ProductRatings
                    upc={product.paUPC || product.smartProductId || product.paSmartProductId}
                    productName={product.name}
                    summary={true}
                    template="detail"
                  />
                </div>
              )}
              <p className="short-description">{product.shortDescription}</p>

              {/*
                SECTION: FRAGRANCE
              */}
              {product.variant?.fragrance && product.variant?.fragrance !== "undefined" && (
                <section>
                  <p>
                    <strong>Scent:</strong> {product.variant?.fragrance}
                  </p>
                </section>
              )}
              {/*
                SECTION: Product Range
              */}
              {productSubBrandReferences && Array.isArray(productSubBrandReferences) ? (
                <section className="rx-product-range">
                  <h2 className="rx-product-range--title">
                    {product.subBrand?.name} {productLabels?.availableFragrances}
                  </h2>
                  <div className="filter-buttons">
                    <button
                      type="button"
                      className={`rx-grid-view-button ${gridView ? "active" : ""}`}
                      onClick={handleGridView}
                    >
                      <GridIcon />
                      <Accessibility location="view grid" />
                    </button>
                    <button
                      type="button"
                      className={`rx-list-view-button ${listView ? "active" : ""}`}
                      onClick={handleListView}
                    >
                      <ListIcon />
                      <Accessibility location="view list" />
                    </button>
                  </div>
                  <div className="rx-product-variations">
                    <ul
                      className={`rx-list rx-list--nb d-flex ${
                        listView ? "rx-list-view-filter active" : "rx-grid-view-filter active"
                      }`}
                    >
                      {productSubBrandReferences.map((item, index) => {
                        return (
                          <React.Fragment key={index}>
                            <li>
                              <a
                                className={item.slug.current === currentSlug ? "active u-rounded" : "u-rounded"}
                                key={index}
                                href={`../../${item.slug.current}`}
                                onMouseEnter={() => setShowVariantText(item.variant?.eVariantName)}
                                onMouseLeave={() => setShowVariantText(defaultFragrance)}
                              >
                                <span style={{ backgroundColor: `${item?.variant?.primaryColor?.hex}` }}></span>
                                {listView ? (
                                  <div className={"rx-product-variations-name"}>{item.variant?.eVariantName}</div>
                                ) : null}
                              </a>
                            </li>
                          </React.Fragment>
                        );
                      })}
                    </ul>
                    {gridView ? (
                      <div className="rx-fragrance-name">
                        <p>{showVariantText}</p>
                      </div>
                    ) : null}
                  </div>
                </section>
              ) : null}

              <div>
                <div ref={binElement} className="action">
                  <WhereToBuyButton
                    productCode={product.smartProductId || product.paSmartProductId || product.paUPC}
                    productName={product.name}
                    productCategory={product.paRetailerCategory || ""}
                    brand={sanitySiteSettings.brandName}
                  />
                </div>

                <section className={`rx-panel rx-panel--fixed ${binPanel ? "is-visible" : ""}`}>
                  <div className="container-fluid d-flex d-flex--sb">
                    <div className="rx-panel-header d-flex">
                      <strong>{product.subBrand?.name}</strong>
                      <p>{product.name}</p>
                    </div>
                    <div>
                      <WhereToBuyButton
                        productCode={product.smartProductId || product.paSmartProductId || product.paUPC}
                        productName={product.name}
                        productCategory={product.paRetailerCategory || ""}
                        brand={sanitySiteSettings.brandName}
                      />
                    </div>
                  </div>
                </section>
              </div>

              {product.paSize && (
                <div>
                  {productLabels?.availableSizes ? productLabels?.availableSizes : "Available Sizes"}: {product.paSize}
                </div>
              )}

              <Row className="details">
                <Col>
                  <Accordion>
                    {product._rawDescription && (
                      <Card data-testid="product-description">
                        <ContextAwareAccordionToggle eventKey="description">
                          <h2>{productLabels?.details}</h2>
                        </ContextAwareAccordionToggle>
                        <Accordion.Collapse eventKey="description">
                          <Card.Body>
                            <BlockContent blocks={product._rawDescription} />
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    )}
                    {product._rawHowToUse && (
                      <Card data-testid="product-how-to-use">
                        <ContextAwareAccordionToggle eventKey="how-to-use">
                          <h2>{productLabels?.howTo}</h2>
                        </ContextAwareAccordionToggle>
                        <Accordion.Collapse eventKey="how-to-use">
                          <Card.Body>
                            <BlockContent blocks={product._rawHowToUse} />
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    )}
                    {product._rawIngredients &&
                      ((product.paSmartLabelId && locale.split("_")[1] == "US") || locale.split("_")[1] !== "US") && (
                        <Card data-testid="product-ingredients">
                          <ContextAwareAccordionToggle eventKey="ingredients">
                            <h2>{productLabels?.ingredients}</h2>
                          </ContextAwareAccordionToggle>
                          <Accordion.Collapse eventKey="ingredients">
                            <Card.Body>
                              <Ingredients
                                text={product._rawIngredients as unknown as PortableText}
                                smartLabelId={product.paSmartLabelId}
                              />
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      )}
                    {product._rawPerfectFor && (
                      <Card data-testid="product-perfect-for">
                        <ContextAwareAccordionToggle eventKey="perfect-for">
                          <h2>{productLabels?.perfectFor}</h2>
                        </ContextAwareAccordionToggle>
                        <Accordion.Collapse eventKey="perfect-for">
                          <Card.Body>
                            <BlockContent blocks={product._rawPerfectFor} />
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    )}
                    {product._rawTryThis && (
                      <Card data-testid="product-try-this">
                        <ContextAwareAccordionToggle eventKey="try-this">
                          <h2>{productLabels?.tryThis}</h2>
                        </ContextAwareAccordionToggle>
                        <Accordion.Collapse eventKey="try-this">
                          <Card.Body>
                            <BlockContent blocks={product._rawTryThis} />
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    )}
                  </Accordion>
                </Col>
              </Row>
              {productLandingPage && product.slug && (
                <div style={{ minWidth: "100px" }}>
                  <SocialShare
                    url={`${siteUrl}${withPrefix(productParentPath)}${product.slug.current}/`}
                    locale={locale}
                    expanded={true}
                    label={productLabels?.share}
                  />
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Container>
      {/*
        SECTION: Video
      */}
      {product.promoVideo?._rawCoverImage ? (
        <section className="rx-video">
          <Container>
            <VideoBlockExperimental {...props.sanityProductV2.promoVideo} />
          </Container>
        </section>
      ) : null}

      <section className="rx-section--product-goes-well">
        <Container>
          <Row>
            <Col>
              {productGoesWellWith?.length > 0 && (
                <ProductSliderV2
                  heading={productLabels?.youMayAlsoLike || ""}
                  content={productGoesWellWith}
                  template="productV2"
                />
              )}
            </Col>
          </Row>
        </Container>
      </section>
      <section className="rx-section--rating-reviews">
        <Container>
          <Row>
            <Col>
              {(product.smartProductId || product.paSmartProductId || product.paUPC) && (
                <ProductReviews
                  gtin={product.paUPC}
                  upc={product.paUPC || product.smartProductId || product.paSmartProductId}
                  productName={product.name}
                  label={productLabels?.reviews}
                  thumbnail={`${urlBuilder
                    .image(product?._rawImages?.[0] || sanitySearch?._rawPlaceholder)
                    .auto("format")
                    .quality(80)
                    .width(250)
                    .height(250)
                    .format("webp")
                    .url()}`}
                />
              )}
            </Col>
          </Row>
        </Container>
      </section>
    </section>
  );
};

export default Product;
