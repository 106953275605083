import React from "react";

export const GridIcon = () => {
  return (
    <svg
      width="14px"
      height="14px"
      viewBox="0 0 14 14"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
    >
      <title>Grid Icon</title>
      <g id="grid" stroke="none" strokeWidth="1" fillRule="evenodd">
        <g id="MNY_S_grid_Default-Existing-Imagery" transform="translate(-25.000000, -680.000000)">
          <g id="Toggle" transform="translate(15.000000, 670.000000)">
            <path
              d="M24,18 L24,24 L18,24 L18,18 L24,18 Z M16,18 L16,24 L10,24 L10,18 L16,18 Z M16,10 L16,16 L10,16 L10,10 L16,10 Z M24,10 L24,16 L18,16 L18,10 L24,10 Z"
              id="Combined-Shape"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
