import React, { FunctionComponent, useContext, useState, useRef, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import sanityImageUrlBuilder from "../../utils/imageUrlBuilder";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import { ImageInterface, SanityColorList } from "../../types/SanityTypes";
import RichText from "../../components/RichText";
import RichTextImage from "../../components/RichTextImage";
import { Play } from "../../images/icons/play";
import { useInView } from "react-intersection-observer";
import { LocalizedContext } from "../../services/LocalizedContextService";
// @ts-ignore
import { Player, ControlBar, ClosedCaptionButton } from "video-react";

// @ts-ignore
import BlockContent from "@sanity/block-content-to-react";
import { event41 } from "../../analytics/event41";
import { event42 } from "../../analytics/event42";
import { event54 } from "../../analytics/event54";
import { event53 } from "../../analytics/event53";
import { event52 } from "../../analytics/event52";
import "./styles.scss";

export interface VideoInterface {
  secondImage?: any;
  _id: any;
  _rawCoverImage: ImageInterface;
  _rawSecondImage?: ImageInterface;
  _rawText?: Record<string, unknown>[];
  video: {
    asset: {
      url: string;
    };
  };
  videoType: {
    name: string;
    _ref?: string;
  };
  colorlist: SanityColorList;
  transcript: {
    asset: {
      url: string;
    };
  };
}

export const PureVideoBlock: FunctionComponent<{
  data: VideoInterface;
  language?: string;
}> = ({ data, language }) => {
  const { sanityId, sanityDataset, locale } = useSiteMetadata(language);
  const urlBuilder = sanityImageUrlBuilder({
    projectId: sanityId,
    dataset: sanityDataset
  });
  const [imageDisplay, setImageDisplay] = useState({ visibility: "visible" } as React.CSSProperties);
  const [videoPlaying, setVideoPlaying] = useState(false);

  const player = useRef(null);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: "5px 0px"
  });

  const videoTheme = {
    videoType: (data as VideoInterface).videoType?.name || (data as VideoInterface).videoType?._ref,
    videoUrl: (data as VideoInterface).video?.asset.url,
    coverImageAlt: (data as VideoInterface)._rawCoverImage?.alt,
    coverImage: (data as VideoInterface)._rawCoverImage,
    text: (data as VideoInterface)._rawText,
    themeColor: (data as VideoInterface).colorlist?.value,
    secondImage: (data as VideoInterface).secondImage,
    _id: (data as VideoInterface)._id,
    transcript: (data as VideoInterface).transcript?.asset.url
  };

  const renderType = () => {
    return videoTheme.videoType?.toLowerCase();
  };

  useEffect(() => {
    let previousPosition = 0;
    const title = videoTheme.coverImageAlt;
    const videoAssetId = videoTheme.videoUrl;

    const onPlay = () => {
      event41(title, videoAssetId);
      // If the user pauses, we don't want to track subsequent play events
      (player?.current as any)?.video?.video?.removeEventListener("play", onPlay);
    };
    const onEnded = () => {
      event42(title, videoAssetId);
      // If the user watches the video again, track it
      (player?.current as any)?.video?.video?.addEventListener("play", onPlay);
    };
    const onTimeUpdate = () => {
      let currentPosition = 0;
      if ((player?.current as any)?.video?.video?.currentTime && (player?.current as any)?.video?.video?.duration) {
        currentPosition =
          (player?.current as any)?.video?.video?.currentTime / (player?.current as any)?.video?.video?.duration;
      }
      if (currentPosition >= 0.75 && previousPosition < 0.75) {
        event54(title, videoAssetId);
      }
      if (currentPosition >= 0.5 && previousPosition < 0.5) {
        event53(title, videoAssetId);
      }
      if (currentPosition >= 0.25 && previousPosition < 0.25) {
        event52(title, videoAssetId);
      }
      previousPosition = currentPosition;
    };

    (player?.current as any)?.video?.video?.addEventListener("play", onPlay);
    (player?.current as any)?.video?.video?.addEventListener("ended", onEnded);
    (player?.current as any)?.video?.video?.addEventListener("timeupdate", onTimeUpdate);

    return () => {
      (player?.current as any)?.video?.video?.removeEventListener("play", onPlay);
      (player?.current as any)?.video?.video?.removeEventListener("ended", onEnded);
      (player?.current as any)?.video?.video?.removeEventListener("timeupdate", onTimeUpdate);
    };
  });

  const toggleVideo = (id: string) => {
    setImageDisplay({ visibility: "hidden" });
    setVideoPlaying(!videoPlaying);
    (player as any)?.current.play();
  };

  const landscapeCoverImage = (image: ImageInterface, imageAlt: string) => {
    return (
      <>
        <picture
          className="placeholder"
          style={
            image.asset.metadata
              ? {
                  background: `url(${image.asset.metadata.lqip})`,
                  backgroundSize: "cover",
                  paddingTop: `56.25%`
                }
              : undefined
          }
        >
          <source
            media={"(min-width: 1200px)"}
            srcSet={
              urlBuilder.image(image).auto("format").quality(80).width(1350).height(780).format("webp").url() ||
              undefined
            }
          />
          <source
            media={"(min-width: 768px)"}
            srcSet={
              urlBuilder.image(image).auto("format").quality(80).width(800).height(460).format("webp").url() ||
              undefined
            }
          />
          <img
            src={
              urlBuilder.image(image).auto("format").quality(80).width(700).height(400).format("webp").url() ||
              undefined
            }
            alt={imageAlt}
            loading={"lazy"}
            className="landscape-cover-image"
          />
        </picture>
        <div className="landscape-play-icon">
          <Play />
        </div>
      </>
    );
  };

  const headingColor = () => {
    return (
      <style>
        {`
          .block-content.color-theme-${data.colorlist.title.toLowerCase()} h2 {color: ${videoTheme.themeColor}}
        `}
      </style>
    );
  };

  const renderVideo = (containerClass: string, id: string, videoType: string) => {
    return (
      <div className={containerClass} hidden={!videoPlaying}>
        {inView ? (
          <Player ref={player}>
            <source src={videoTheme.videoUrl} type="video/mp4" />
            <track kind="captions" src={videoTheme.transcript} label="English" default />
            <ControlBar autoHide={false}>
              <ClosedCaptionButton order={7} />
            </ControlBar>
          </Player>
        ) : null}
      </div>
    );
  };

  const renderLandscapeVideoAndImage = (imageClassId: string, videoClassId: string) => {
    return (
      <>
        <button
          onClick={() => toggleVideo(videoTheme.coverImageAlt as string)}
          className={imageClassId}
          style={imageDisplay}
        >
          {landscapeCoverImage(videoTheme.coverImage as any, videoTheme.coverImageAlt as string)}
          <span className="sr-only sr-only-focusable">Play {videoTheme.coverImageAlt}</span>
        </button>
        {renderVideo(videoClassId, videoTheme.coverImageAlt as string, "landscape-video")}
      </>
    );
  };

  const renderLandscapeTextBox = (textBoxPosition: string) => {
    return (
      <div
        className={"text-box rich-text rich-text-block " + textBoxPosition}
        style={{ borderColor: videoTheme.themeColor }}
      >
        <div className="align-self-center">
          {headingColor()}
          <div className={`block-content color-theme-${data.colorlist.title.toLowerCase()}`}>
            <BlockContent blocks={videoTheme.text} />
          </div>
        </div>
      </div>
    );
  };

  const squareVideoAndImage = (
    <>
      <div
        onClick={() => toggleVideo(videoTheme.coverImageAlt as string)}
        className="square-cover-image"
        style={imageDisplay}
      >
        <RichTextImage data={videoTheme.coverImage} type="square-video-image" />
        <button className="square-play-icon">
          <Play />
          <span className="sr-only sr-only-focusable">Play {videoTheme.coverImageAlt}</span>
        </button>
      </div>
      <div>{renderVideo("video-container", videoTheme.coverImageAlt as string, "square-video")}</div>
    </>
  );

  if (renderType() === "landscape-right") {
    return (
      <section
        className="video-block--experimental rich-text"
        id={videoTheme._id}
        ref={ref}
        data-testid="landscape-right"
      >
        <Container fluid>
          <Row className="no-gutters d-flex">
            <Col lg={8} md={12} className="order-lg-2" style={{ height: "fit-content" }}>
              {renderLandscapeVideoAndImage("right-landscape-image btn", "right-video")}
            </Col>
            <Col lg={4} md={12}>
              {renderLandscapeTextBox("text-box-left")}
            </Col>
          </Row>
        </Container>
      </section>
    );
  }

  if (renderType() === "landscape-left") {
    return (
      <section
        className="video-block--experimental rich-text"
        id={videoTheme._id}
        ref={ref}
        data-testid="landscape-left"
      >
        <Container fluid>
          <Row className="no-gutters">
            <Col lg={8} md={12} className="left-landscape-vid-top-margin" style={{ height: "fit-content" }}>
              {renderLandscapeVideoAndImage("left-landscape-image btn", "left-video")}
            </Col>
            <Col lg={4} md={12}>
              {renderLandscapeTextBox("text-box-right")}
            </Col>
          </Row>
        </Container>
      </section>
    );
  }

  if (renderType() === "square-left") {
    return (
      <section className="video-block--experimental" id={videoTheme._id} ref={ref} data-testid="square-left">
        <Container fluid className="rich-text">
          <Row className="d-flex">
            <Col lg={6} md={12}>
              {squareVideoAndImage}
            </Col>
            <Col lg={6} md={12}>
              <RichText data={videoTheme.text} />
            </Col>
          </Row>
        </Container>
      </section>
    );
  }

  if (renderType() === "square-centre") {
    return (
      <Container
        id={videoTheme._id}
        fluid
        ref={ref}
        className="rich-text video-block--experimental"
        data-testid="square-centre"
      >
        <Row className="d-flex">
          <Col lg={3} className="order-lg-2 second-image" data-testid="square-centre-small">
            <RichTextImage data={videoTheme.secondImage as ImageInterface} type="small" />
          </Col>
          <Col lg={{ offset: 3 }} md={12}>
            {squareVideoAndImage}
            <div className="square-text-position">
              <RichText data={videoTheme.text} />
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  if (renderType() === "square-right") {
    return (
      <Container
        id={videoTheme._id}
        fluid
        ref={ref}
        className="rich-text video-block--experimental"
        data-testid="square-right"
      >
        <Row className="d-flex">
          <Col lg={{ offset: 3 }} className="second-image" data-testid="square-right-small">
            <RichTextImage data={videoTheme.secondImage as ImageInterface} type="small" />
          </Col>
          <Col lg={6} md={12}>
            {squareVideoAndImage}
            <div className="square-text-position">
              <RichText data={videoTheme.text} />
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  if (!renderType()) {
    return (
      <section
        className="video-block--experimental rich-text is-videOnly"
        id={videoTheme._id}
        ref={ref}
        data-testid="landscape-left"
      >
        <Container fluid>
          <Row className="no-gutters">
            <Col className="grid-column" lg={12} md={12}>
              {renderLandscapeVideoAndImage("left-landscape-image btn", "left-video")}
            </Col>
          </Row>
        </Container>
      </section>
    );
  }

  return null;
};

const VideoBlockExperimental: FunctionComponent = props => {
  const { language } = useContext(LocalizedContext);
  return <PureVideoBlock data={props as any} language={language} />;
};

export default VideoBlockExperimental;
