import React from "react";

export const ListIcon = () => {
  return (
    <svg
      width="14px"
      height="12px"
      viewBox="0 0 14 12"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
    >
      <title>List View</title>
      <g id="list" stroke="none" strokeWidth="1" fillRule="evenodd">
        <g id="MNY_S_list_Default-Existing-Imagery" transform="translate(-58.000000, -681.000000)">
          <g id="Group-2" transform="translate(15.000000, 670.000000)">
            <g id="Group-4" transform="translate(43.000000, 11.000000)">
              <rect id="Rectangle-Copy-9" x="0" y="0" width="14" height="2"></rect>
              <rect id="Rectangle-Copy-10" x="0" y="5" width="14" height="2"></rect>
              <rect id="Rectangle-Copy-11" x="0" y="10" width="14" height="2"></rect>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
